import React, { FormEvent, useEffect } from "react";
import TextInput from "../utils/TextInput";
import Button from "../utils/button.component";
import { loadStreams } from "../../store/actions/stream.action";
import { useConfig } from "../../hooks/use-config.hook";
import poweredBy from "../../images/powered-by-eventio_horizontal.svg";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux.hook";

const CheckTicketCode: React.FC = (props) => {
    const [ticketCode, setTicketCode] = React.useState("");

    const dispatch = useAppDispatch();

    const streamState = useAppSelector(state => state.stream);

    const config = useConfig();

    const location = useLocation();

    const checkTicketCode = async (event: FormEvent) => {
        event.preventDefault();
        dispatch(loadStreams(ticketCode));
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);

        const ticket_code = urlParams.get("ticket_code");

        if (ticket_code) {
            setTicketCode(ticket_code);
        }
    }, []);

    return (
        <div className="max-w-md w-full">
            {config.theme.header_img && (
                <div
                    className="bg-contain bg-no-repeat bg-center mb-4"
                    style={{
                        backgroundImage: `url(${config.theme.header_img})`,
                    }}
                >
                    <img
                        src={config.theme.header_img}
                        alt=""
                        className="invisible"
                    />
                </div>
            )}
            <div className="bg-white px-4 py-6 rounded-md shadow">
                <form onSubmit={checkTicketCode} className="space-y-4">
                    {streamState.view_validation_failed && (
                        <div className="rounded-md bg-red-50 p-4 mb-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <svg
                                        className="h-5 w-5 text-red-400"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm leading-5 font-medium text-red-800">
                                        Lähetyksen tai tallenteen katselu on
                                        aloitettu toisella laitteella.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="space-y-2">
                        <TextInput
                            onChange={(event) =>
                                setTicketCode(event.target.value)
                            }
                            value={ticketCode}
                            label="Lipputunniste"
                        />
                        {streamState.isLoaded && streamState.isEmpty && (
                            <p className="text-sm text-red-600" id="pin-error">
                                Lipputunnistetta ei löytynyt.
                            </p>
                        )}
                        <p className="text-sm">
                            Löydät lipputunnisteen sähköpostitse toimitetusta
                            PDF-lipusta.{" "}
                            <a
                                href="https://tuki.eventio.fi/article/63-mista-loydan-lipputunnisteen-virtuaalitapahtumaan-liittymiseksi"
                                className="text-primary-500 hover:text-primary-700 duration-150"
                            >
                                Lisätietoja
                            </a>
                            .
                        </p>
                        <p className="text-sm">
                            Voit testata lähetyksen toimivuutta
                            lipputunnisteella <b>TESTI</b>.
                        </p>
                        <p className="text-sm">
                            Ongelmia striimin katselussa?{" "}
                            <Link
                                to="/contact-us"
                                className="text-primary-500 hover:text-primary-700 duration-150"
                            >
                                Ota yhteyttä!
                            </Link>
                        </p>
                    </div>
                    <Button color="primary" type="submit" fullWidth={true}>
                        Avaa
                    </Button>
                </form>
            </div>
            <div className="mt-8 w-full justify-center flex">
                <a href="https://eventio.fi">
                    <img
                        src={poweredBy}
                        alt="Powered by Eventio"
                        className="h-10 self-center"
                    />
                </a>
            </div>
        </div>
    );
};

export default CheckTicketCode;
