import React, { useEffect } from "react";
import CheckTicketCode from "./check-ticket-code.component";
import StreamPlayer from "./stream-player.component";
import StreamList from "./stream-list.component";
import { loadStreams } from "../../store/actions/stream.action";
import { useConfig } from "../../hooks/use-config.hook";
import { useLocation } from "@reach/router";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { translationHelper } from "../../helpers/translation.helper";
import { Helmet } from "react-helmet";
import NoSSR from "../utils/no-ssr.component";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux.hook";

const Header: React.FC = (props) => {
    const config = useConfig();

    const location = useLocation();

    return (
        <>
            <GatsbySeo
                nofollow={true}
                title={`Livestriimi - ${translationHelper(config.title, "fi")}`}
                description={"Seuraa livelähetyksiä omalla selaimellasi."}
                openGraph={{
                    url: location && location.href,
                    title: `Livestriimi - ${translationHelper(
                        config.title,
                        "fi",
                    )}`,
                    description: "Seuraa livelähetystä omalla selaimellasi.",
                    images: config.theme.header_img
                        ? [{ url: config.theme.header_img }]
                        : [],
                    type: "og:website",
                }}
                twitter={{
                    handle: "@handle",
                    site: "@site",
                    cardType: "summary_large_image",
                }}
            />
            <Helmet>
                <title>
                    Livestriimi - {translationHelper(config.title, "fi")}
                </title>
                <link
                    rel="icon"
                    sizes="48x48"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-48x48.png`}
                />
                <link
                    rel="icon"
                    sizes="72x72"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-72x72.png`}
                />
                <link
                    rel="icon"
                    sizes="96x96"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-96x96.png`}
                />
                <link
                    rel="icon"
                    sizes="144x144"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-144x144.png`}
                />
                <link
                    rel="icon"
                    sizes="192x192"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-192x192.png`}
                />
                <link
                    rel="icon"
                    sizes="256x256"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-256x256.png`}
                />
                <link
                    rel="icon"
                    sizes="384x384"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-384x384.png`}
                />
                <link
                    rel="icon"
                    sizes="512x512"
                    href={`${process.env.GATSBY_WEBSITE_URL}/icons/icon-512x512.png`}
                />
            </Helmet>
        </>
    );
};

const StreamPage: React.FC = (props) => {
    const streamState = useAppSelector(state => state.stream);
    const [selectedStream, setSelectedStream] = React.useState<string | undefined>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        const stream_ticket_code = sessionStorage.getItem("stream_ticket_code");
        if (stream_ticket_code) {
            dispatch(loadStreams(stream_ticket_code));
        }
    }, []);

    return (
        <div className="min-h-full w-full flex justify-center items-center">
            <Header />
            <NoSSR>
                {((streamState.isLoaded && streamState.isEmpty) ||
                    (!streamState.isLoaded && !streamState.isEmpty)) && (
                    <CheckTicketCode />
                )}
                {streamState.isLoaded &&
                !streamState.isEmpty &&
                !selectedStream && (
                    <StreamList setSelectedStream={setSelectedStream} />
                )}
                {streamState.isLoaded &&
                !streamState.isEmpty &&
                selectedStream && (
                    <StreamPlayer
                        stream_id={selectedStream}
                        setSelectedStream={setSelectedStream}
                    />
                )}
            </NoSSR>
        </div>
    );
};

export default StreamPage;
