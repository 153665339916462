import { TranslatableStringInterface } from "./translatable-string.interface";

export enum StreamStatus {
    PLAYBACK_OK = "playback_ok",
    WAIT_FOR_START = "wait_for_start",
    NOT_AVAILABLE = "not_available",
}

export interface StreamInterface {
    status: StreamStatus;
    event: {
        id: string;
        title: TranslatableStringInterface;
    };
    stream: {
        playback_url: string | undefined;
        playback_opens_at: Date | null;
        playback_closes_at: Date | null;
        stream_info: TranslatableStringInterface | null;
    };
}
