import React, { useEffect } from "react";
import { StreamInterface, StreamStatus } from "../../interfaces/stream.interface";
import { translationHelper } from "../../helpers/translation.helper";
import { useTranslation } from "react-i18next";
import { loadStreams, validateView } from "../../store/actions/stream.action";
import poweredBy from "../../images/powered-by-eventio_horizontal.svg";
import Button from "../utils/button.component";
import ReactPlayer from "react-player";
import { useAppDispatch, useAppSelector } from "../../hooks/use-redux.hook";

interface StreamPlayerProps {
    stream_id: string;
    setSelectedStream: (value: string | undefined) => void;
}

const Player: React.FC<{
    stream: StreamInterface;
    setSelectedStream: (value: string | undefined) => void;
}> = (props) => {
    const { i18n } = useTranslation();

    const dispatch = useAppDispatch();

    const ticket_code = useAppSelector(
        state => state.stream.ticket_code,
    );

    const ticket = useAppSelector(state => state.stream.ticket);

    const { stream, setSelectedStream } = props;

    if (stream.status === StreamStatus.WAIT_FOR_START) {
        setTimeout(() => {
            dispatch(loadStreams(ticket_code));
        }, 10000);
    }

    useEffect(() => {
        if (ticket_code.toLowerCase() === "testi") return;
        let interval: any;
        if (ticket) {
            interval = setInterval(() => {
                dispatch(validateView());
            }, 1000 * ticket.viewer.ttl - 1000 * 30);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [ticket, ticket_code]);

    return (
        <>
            {stream.status === StreamStatus.PLAYBACK_OK && (
                <div className="aspect-w-16 aspect-h-9">
                    <ReactPlayer
                        url={stream.stream.playback_url || ""}
                        autoPlay={false}
                        controls={true}
                        width="100%"
                        height="100%"
                    />
                </div>
            )}
            {stream.status === StreamStatus.WAIT_FOR_START && (
                <div className="w-full h-64 bg-gray-300 flex justify-center items-center rounded-md">
                    <h3>Lähetys odottaa alkamista.</h3>
                </div>
            )}
            <div className="pb-5">
                <div className="sm:flex sm:justify-between sm:items-baseline px-2 md:px-4 lg:px-0">
                    <div className="sm:w-0 sm:flex-1">
                        <h1
                            id="stream-title"
                            className="text-xl font-medium text-gray-900"
                        >
                            {translationHelper(
                                stream.event.title,
                                i18n.language,
                            )}
                        </h1>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                            {stream.stream.stream_info &&
                            translationHelper(
                                stream.stream.stream_info,
                                i18n.language,
                            )}
                        </p>
                    </div>

                    <div
                        className="mt-4 flex items-center justify-between sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:justify-start">
                        <div className="mt-4 float-right">
                            <Button
                                color="primary"
                                type="button"
                                onClick={() => setSelectedStream(undefined)}
                            >
                                Poistu
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const StreamPlayer: React.FC<StreamPlayerProps> = (props) => {
    const { stream_id, setSelectedStream } = props;

    const ticket = useAppSelector(state => state.stream.ticket);
    const stream = ticket?.streams.find(
        (stream) => stream.event.id === stream_id,
    );

    const event = useAppSelector(state =>
        state.event.events.find((event) => event.id === stream_id),
    );

    // Kun eventti vaihtuu ja se on live-eventti, uudelleenohjataan livesivulle katsomaan striimiä [ch1724]
    useEffect(() => {
        if (event && event.live_screen_enabled && window) {
            window.location.href = `/live/${event.id}/`;
        }
    }, [event]);

    if (typeof window !== `undefined` && stream?.event != null) {
        // Lähetetään heti alussa, ennen kuin aletaan looppaamaan
        window.gtag("event", "stream", {
            event_label: stream_id,
            event_category: event?.stream?.type,
        });
        setInterval(() => {
            window.gtag("event", "stream", {
                event_label: stream_id,
                event_category: event?.stream?.type,
            });
        }, 1000 * 60 * 5);
    }

    return (
        <div className="max-w-7xl w-full">
            {!stream && <p>Lähetys ei ole saatavilla</p>}
            {stream && (
                <Player stream={stream} setSelectedStream={setSelectedStream} />
            )}
            <div className="mt-8 w-full justify-center flex">
                <a href="https://eventio.fi">
                    <img
                        src={poweredBy}
                        alt="Powered by Eventio"
                        className="h-10 self-center"
                    />
                </a>
            </div>
        </div>
    );
};

export default StreamPlayer;
