import React from "react";
import Button from "../utils/button.component";
import { translationHelper } from "../../helpers/translation.helper";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useConfig } from "../../hooks/use-config.hook";
import poweredBy from "../../images/powered-by-eventio_horizontal.svg";
import { useAppSelector } from "../../hooks/use-redux.hook";

interface StreamListProps {
    setSelectedStream: (value: string) => void;
}

const StreamList: React.FC<StreamListProps> = (props) => {
    const { i18n } = useTranslation();

    const config = useConfig();

    const { setSelectedStream } = props;

    const streamState = useAppSelector(state => state.stream);

    return (
        <div className="max-w-md w-full px-4 lg:px-0">
            <div className="w-full flex justify-center mb-5">
                {config.theme.header_img && (
                    <img src={config.theme.header_img} alt="" />
                )}
            </div>
            <div className="pb-3 border-b border-gray-200">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Saatavilla olevat lähetykset
                </h3>
            </div>
            <ul
                className="space-y-4"
                role="radiogroup"
                aria-labelledby="radiogroup-label"
            >
                {streamState.ticket?.streams.map((stream, i) => {
                    return (
                        <li
                            id="radiogroup-option-0"
                            key={i}
                            tabIndex={i}
                            role="radio"
                            aria-checked="true"
                            className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => setSelectedStream(stream.event.id)}
                        >
                            <div
                                className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                                <div className="flex items-center">
                                    <div className="text-sm">
                                        <p className="font-medium text-gray-900">
                                            {translationHelper(
                                                stream.event.title,
                                                i18n.language,
                                            )}
                                        </p>
                                        <div className="text-gray-500">
                                            {stream.stream
                                                .playback_opens_at && (
                                                <p className="sm:inline">
                                                    Alkaa:{" "}
                                                    {moment(
                                                        stream.stream
                                                            .playback_opens_at,
                                                    ).format("D.M.YYYY H:mm")}
                                                </p>
                                            )}
                                            {stream.stream
                                                .playback_closes_at && (
                                                <>
                                                    {" "}
                                                    <span
                                                        className="hidden sm:inline sm:mx-1"
                                                        aria-hidden="true"
                                                    >
                                                        &middot;
                                                    </span>
                                                    <p className="sm:inline">
                                                        Päättyy:{" "}
                                                        {moment(
                                                            stream.stream
                                                                .playback_closes_at,
                                                        ).format(
                                                            "D.M.YYYY HH:mm",
                                                        )}{" "}
                                                    </p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <Button
                                        color="primary"
                                        type="button"
                                        onClick={() =>
                                            setSelectedStream(stream.event.id)
                                        }
                                    >
                                        Avaa
                                    </Button>
                                </div>
                            </div>
                            <div
                                className="border-primary-500 absolute inset-0 rounded-lg border-2 pointer-events-none"
                                aria-hidden="true"
                            />
                        </li>
                    );
                })}
            </ul>
            <div className="mt-4 space-y-2">
                <Button
                    color="primary"
                    type="button"
                    fullWidth={true}
                    onClick={() => {
                        sessionStorage.removeItem("stream_ticket_code");
                        window.location.reload();
                    }}
                >
                    Vaihda lippukoodia
                </Button>
                <Button
                    color="primary"
                    type="button"
                    fullWidth={true}
                    onClick={() => {
                        window.location.href =
                            process.env.GATSBY_WEBSITE_URL || "";
                    }}
                >
                    Siirry verkkokauppaan
                </Button>
            </div>
            <div className="mt-8 w-full justify-center flex">
                <a href="https://eventio.fi">
                    <img
                        src={poweredBy}
                        alt="Powered by Eventio"
                        className="h-10 self-center"
                    />
                </a>
            </div>
        </div>
    );
};

export default StreamList;
