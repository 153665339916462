import axios from "axios";
import { StreamActionType } from "../reducers/stream.reducer";
import { StreamTicketInterface } from "../../interfaces/stream-ticket.interface";
import { AppState } from "../reducers";

export const loadStreams = (ticket_code: string) => async (dispatch: any) => {
    // TODO: Fix testikoodi
    if (ticket_code.toLowerCase() === "testi") {
        return dispatch({
            type: StreamActionType.ADD_STREAMS,
            ticket: {
                ticket: {
                    id: "testi",
                },
                viewer: {
                    id: "testi",
                    ttl: 1000000000000000000000,
                },
                streams: [
                    {
                        status: "playback_ok",
                        event: {
                            id: "1234567890",
                            title: {
                                fi: "Testistriimi",
                                sv: "Testistriimi",
                                de: "Testistriimi",
                                en: "Testistriimi",
                            },
                        },
                        stream: {
                            playback_url:
                                "https://stream.mux.com/1hWcNfxakMGkd02x00ZGNXJvyo53gzMiHTguzm007lauyI.m3u8",
                            playback_opens_at: new Date(),
                            playback_closes_at: new Date(
                                new Date().getTime() + 30 * 60 * 1000
                            ),
                        },
                    },
                ],
            },
            ticket_code,
        });
    }

    try {
        const response = await axios.post<StreamTicketInterface>(
            `${process.env.GATSBY_WS_API_URL}/v1/${process.env.GATSBY_WS_ID}/streams/view_by_ticket.json`,
            { ticket_id: ticket_code.toUpperCase() }
        );

        sessionStorage.setItem("stream_ticket_code", ticket_code.toUpperCase());

        return dispatch({
            type: StreamActionType.ADD_STREAMS,
            ticket: response.data,
            ticket_code: ticket_code.toUpperCase(),
        });
    } catch (error) {
        if (error.response.status === 404) {
            return dispatch({ type: StreamActionType.UNKNOWN_TICKET_CODE });
        }
    }
};

export const validateView = () => async (
    dispatch: any,
    getState: () => AppState
) => {
    const { stream } = getState();

    try {
        const response = await axios.post<StreamTicketInterface>(
            `${process.env.GATSBY_WS_API_URL}/v1/${process.env.GATSBY_WS_ID}/streams/validate_view.json`,
            {
                ticket: {
                    id: stream.ticket?.ticket.id,
                },
                viewer: {
                    id: stream.ticket?.viewer.id,
                    ttl: stream.ticket?.viewer.ttl,
                },
            }
        );

        return dispatch({
            type: StreamActionType.UPDATE_VIEWER_ID,
            viewer_id: response.data.viewer.id,
        });
    } catch (error) {
        if (error.response.status === 404) {
            return dispatch({ type: StreamActionType.VIEW_VALIDATION_FAILED });
        }
    }
};
